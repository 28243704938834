import {Button} from '@mui/material';
import {ReactNode, useState} from 'react';
import {useMatches} from '../../utils/CommonUtils';

type Props = {
    readonly icon?: ReactNode;
    readonly value?: any;
    readonly title?: string;
    readonly fixWidth?: boolean;
    readonly fixHeight?: boolean;
    readonly fitContent?: boolean;
    readonly disabled?: boolean;
    readonly defaultColor?: boolean;
    readonly initialState?: boolean;
    readonly changeLocalValue?: (value: any) => void;
};

export const CustomButtonStyle = ({icon, title, initialState, fixWidth, fitContent, changeLocalValue, fixHeight, disabled, defaultColor, value}: Props) => {
    const matches = useMatches();

    const [button, setButton] = useState(() => initialState ?? false);

    const handleClickButton = () => {
        changeLocalValue?.(value);
        setButton((prevState) => !prevState);
    };

    return (
        <Button
            sx={(theme) => ({
                padding: '10px 20px',
                borderStyle: 'solid',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'fit-content',
                height: 'fit-content',
                borderRadius: 2,
                borderWidth: '2px',
                cursor: 'pointer',
                marginRight: 2,
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}26`,
                },
                ...(button && {
                    backgroundColor: `${theme.palette.primary.main}26`,
                    '&:hover': {
                        backgroundColor: `${theme.palette.primary.main}26`,
                    },
                }),
                ...(defaultColor && {
                    backgroundColor: `${theme.palette.primary.main}26`,
                    '&:hover': {
                        backgroundColor: `${theme.palette.primary.main}26`,
                    },
                }),
                ...((icon || fixWidth) &&
                    !matches && {
                        width: 120,
                        height: 90,
                    }),
                ...(fixHeight &&
                    !matches && {
                        width: 120,
                        height: 120,
                    }),
                ...(matches && {
                    width: 120,
                }),
                ...(matches &&
                    fixWidth && {
                        width: 120,
                    }),
                ...(matches &&
                    fixHeight && {
                        height: 88,
                    }),
                ...(fitContent && {
                    width: 'fit-content',
                    height: 'fit-content',
                }),
            })}
            color="inherit"
            onClick={disabled ? undefined : handleClickButton}
            disabled={disabled}
        >
            {icon}
            {title}
        </Button>
    );
};
