import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Image from 'next/image';

import {ActionButton} from '../../../common/components/buttons/ActionButton';
import {DialogTitleWithClose} from '../../../common/components/detail/DialogTitleWithClose';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Komodita} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {useSelector} from 'react-redux';
// @ts-expect-error : Add file to public
import vyjadreniGas from '../../../../public/static/images/Vzor_gas.png';
// @ts-expect-error : Add file to public
import vyjadreniEgd from '../../../../public/static/images/Vzor_egd.png';
import {Store} from '../../../lib/StoreType';
type Props = {
    readonly onClose: () => void;
};
export const StepOneNapoveda = ({onClose}: Props) => {
    const komodita = useSelector((state: Store) => state.common.komodita);
    return (
        <Dialog open keepMounted onClose={onClose} fullWidth>
            <DialogTitleWithClose title="step.one.napoveda" onClose={onClose} />

            <DialogContent>
                <Image alt="Napoveda" src={komodita === Komodita.ELEKTRINA ? vyjadreniEgd : vyjadreniGas} width={550} height={800} />
            </DialogContent>
            <DialogActions>
                <ActionButton size="small" color="primary" variant="contained" onClick={onClose} caption={'button.zavrit'} />
            </DialogActions>
        </Dialog>
    );
};
