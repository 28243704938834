import {Maybe, SouhlasAdresa, SouhlasInvestor, SouhlasPriloha, SouhlasZadatel} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';

export const Zahranici = {
    ANO: 'ANO',
    NE: 'NE',
} as const;

export type Zahranici = (typeof Zahranici)[keyof typeof Zahranici];

export type SouhlasOsobniUdajeInputCustom = {
    investor: Maybe<SouhlasInvestor & {zahranici?: Zahranici; adresa: Maybe<SouhlasAdresa> | undefined}> | undefined;
    zadatel: (Maybe<Omit<SouhlasZadatel, 'adresa'>> & {zahranici: Zahranici; adresa: SouhlasAdresa | undefined}) | undefined;
    zadatelJeInvestor: Maybe<boolean> | undefined;
};

export type SouhlasTechnickaSpecifikaceCustom = {
    udajeOStavbeNazev?: Maybe<string> | undefined;
    udajeOStavbePopis?: Maybe<string> | undefined;
    prilohy?: Maybe<SouhlasPriloha[]> | undefined;
};

export type SouhlasInputCustomData = {
    id?: string;
    investor: Maybe<SouhlasInvestor & {zahranici?: Zahranici}> | undefined;
    technickaSpecifikace: Maybe<SouhlasTechnickaSpecifikaceCustom> | undefined;
    zadatel: Maybe<SouhlasZadatel> & {zahranici: Zahranici};
    zadatelJeInvestor: Maybe<boolean>;
};

export type SouhlasFormData = {
    id: string;
    stepTwo: SouhlasOsobniUdajeInputCustom | undefined;
    stepThree: SouhlasTechnickaSpecifikaceCustom | undefined;
};
