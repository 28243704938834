import {PaperProps, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';
import {Span} from '../../styledComponents/Span';
import {useMatches} from '../../utils/CommonUtils';

type PaperBlockProps = PaperProps & {
    readonly titleBlock: string | JSX.Element | undefined;
    readonly description?: string | JSX.Element;
    readonly titleBlockIcon?: JSX.Element | string;
    readonly titleRightBlock?: JSX.Element | string;
    readonly centerTitle?: boolean;
    readonly disabledChildrenMargin?: boolean;
};

export const PaperBlock: FC<PaperBlockProps> = ({children, titleBlock, titleBlockIcon, description, centerTitle, titleRightBlock, disabledChildrenMargin}) => {
    const matches = useMatches();

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}} sx={{textAlign: centerTitle ? 'center' : undefined}}>
                <Span
                    sx={
                        centerTitle
                            ? {
                                  display: 'contents',
                                  textAlign: 'center',
                              }
                            : {
                                  display: 'flex',
                                  alignItems: 'center',
                              }
                    }
                >
                    {titleBlockIcon}
                    {titleBlock && (
                        <Typography
                            variant={matches ? 'h5' : 'h4'}
                            sx={{
                                marginLeft: 0,
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                            }}
                        >
                            {typeof titleBlock === 'string' ? <FormattedMessage id={titleBlock} /> : titleBlock}
                        </Typography>
                    )}
                    {titleRightBlock && (
                        <Div
                            sx={{
                                marginLeft: 'auto',
                            }}
                        >
                            {titleRightBlock}
                        </Div>
                    )}
                </Span>
                {description && (
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginLeft: centerTitle ? undefined : matches && centerTitle ? 0 : 6.5,
                        }}
                    >
                        {typeof description === 'string' ? <FormattedMessage id={description} /> : description}
                    </Typography>
                )}
            </Grid>

            <Grid
                size={{xs: 12}}
                sx={(theme) => ({
                    margin: disabledChildrenMargin
                        ? theme.spacing(matches ? 3 : 10, matches ? 0 : 7, matches ? 3 : 10, matches ? 0 : 2)
                        : theme.spacing(matches ? 3 : 10, matches ? 0 : 7, matches ? 3 : 10, matches ? 0 : 7),
                })}
            >
                {children}
            </Grid>
        </Grid>
    );
};
