import {Komodita} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import Head from 'next/head';

type Props = {
    komodita: Komodita;
};

export const DynamicFavicon = ({komodita}: Props) => {
    const elektrinaIcons = {
        apple: '/static/images/assets/icons//icon-48x48.png',
        favicon: '/static/images/favicon.ico',
    };

    const plynIcons = {
        apple: '/static/images/favicongas.png',
        favicon: '/static/images/favicongas.png',
    };

    const emptyIcons = {
        apple: '',
        favicon: '',
    };

    const icons = komodita === Komodita.ELEKTRINA ? elektrinaIcons : komodita === Komodita.PLYN ? plynIcons : emptyIcons;

    return (
        <Head>
            <link rel="apple-touch-icon" href={icons.apple} key="apple-icon" />
            <link rel="icon" type="image/x-icon" href={icons.favicon} key="favicon" />
            <link rel="shortcut icon" type="image/x-icon" href={icons.favicon} key="shortcut-icon" />
        </Head>
    );
};
