import {SvgIconProps} from '@mui/material';
import {FC} from 'react';

type Props = SvgIconProps & {
    readonly logoColor?: string;
};

export const ElectricityIcon: FC<Props> = (props) => (
    <svg className="main-landing-page__icon" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 120 120">
        <defs>
            <linearGradient id="energy-gradient-b" x1="64.113%" x2="33.867%" y1="9.062%" y2="97.935%">
                <stop offset="0%" stopColor={props.logoColor ?? '#261B62'} />
                <stop offset="100%" stopColor={props.logoColor ?? '#E63323'} />
            </linearGradient>
            <path
                id="energy-gradient-a"
                d="M7.528 42.001l19.938.078-10.176 33.53L57.334 27.05H33.616L49.588 6.011H29.842a2.991 2.991 0 00-2.604 1.515L7.528 42.001zm1.698 47.187a3.6 3.6 0 01-1.164-3.84l11.31-37.258H3.78C.935 48.09-.713 45 .3 42.64c-.01 0 7.23-12.699 21.723-38.096A9.016 9.016 0 0129.842 0h25.514c2.34 0 4.82 2.278 2.34 5.408L45.717 21.04h18.197l.77 6.502-50.401 61.155A3.59 3.59 0 0111.506 90c-.81 0-1.614-.264-2.28-.812z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(27 17.999)">
            <use fill={props.logoColor ?? '#261B62'} href="#energy-gradient-a" />
            <path
                fill="url(#energy-gradient-b)"
                fillRule="nonzero"
                d="M34.832 61.771c-.01 0 8.157-14.35 24.5-43.05l4.034 4.867-23.208 41.158c-1.817 3.154-7.236.835-5.326-2.975z"
                transform="rotate(-170 48.898 42.472)"
            />
        </g>
    </svg>
);
