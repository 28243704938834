import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {WhatshotOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {useCommonAction} from '../common/action/CommonAction';
import {CustomButtonStyle} from '../common/components/buttons/CustomButtonStyle';
import {ElectricityIcon} from '../common/components/icons/ElectricityIcon';
import {EgdLogo} from '../common/components/layout/EgdLogo';
import {GasLogo} from '../common/components/layout/GasLogo';
import {PaperBlock} from '../common/components/layout/PaperBlock';
import {Div} from '../common/styledComponents/Div';
import {changeFavicon, useMatches} from '../common/utils/CommonUtils';
import {Store} from '../lib/StoreType';
import {theme} from '../lib/theme';
import {StepOne} from './stepOne/components/StepOne';

/**
 * Komponenta pro zobrazení úvodního rozcestníku
 */
export const Rozcestnik = () => {
    // methods
    const {setKomodita} = useCommonAction();
    const matches = useMatches();

    // redux state
    const komodita = useSelector((state: Store) => state.common.komodita);
    const formKind = useSelector((state: Store) => state.common.formKind);

    //local variables
    const width = matches ? 100 : 250;
    const height = matches ? 50 : 120;
    const odstavitElektrina = formKind?.SCHVALOVACKA?.ELEKTRINA?.komodita === Komodita.ELEKTRINA && !formKind?.SCHVALOVACKA?.ELEKTRINA?.aktivni;
    const odstavitPlyn = formKind?.SCHVALOVACKA?.PLYN?.komodita === Komodita.PLYN && !formKind?.SCHVALOVACKA?.PLYN?.aktivni;

    const handleChangeKomodita = (komodita: Komodita) => {
        setKomodita(komodita);
        // nastaveni komodity do cookie
        changeFavicon(komodita);
    };

    if (komodita === Komodita.ELEKTRINA) {
        changeFavicon(komodita);
        return <StepOne />;
    }
    if (komodita === Komodita.PLYN) {
        changeFavicon(komodita);
        return <StepOne />;
    }

    if (!komodita) {
        document.cookie = `komodita=; path=/`;
    }

    return (
        <PaperBlock
            titleBlock={<FormattedMessage id="formular.nove.pripojeni.bez.komodity" />}
            description={<FormattedMessage id="formular.nove.pripojeni.bez.komodity.description" />}
            centerTitle
        >
            <Grid container>
                <Grid size={{xs: 12}} sx={{display: 'flex', justifyContent: 'center'}}>
                    <CustomButtonStyle
                        value={Komodita.ELEKTRINA}
                        icon={
                            <Div>
                                <ElectricityIcon
                                    width={width / 1.1}
                                    height={height / 1.1}
                                    logoColor={odstavitElektrina ? theme.palette.grey[500] : undefined}
                                />
                                <EgdLogo width={width} height={height} pathFill={odstavitElektrina ? theme.palette.grey[500] : theme.palette.error.main} />
                            </Div>
                        }
                        changeLocalValue={handleChangeKomodita}
                        disabled={odstavitElektrina}
                        fitContent
                    />
                    <CustomButtonStyle
                        value={Komodita.PLYN}
                        icon={
                            <Div>
                                <WhatshotOutlined sx={{width: width / 1.1, height: height / 1.1, color: odstavitPlyn ? theme.palette.grey[500] : 'inherit'}} />
                                <GasLogo width={width} height={height} pathFill={odstavitPlyn ? theme.palette.grey[500] : 'inherit'} />
                            </Div>
                        }
                        changeLocalValue={handleChangeKomodita}
                        disabled={odstavitPlyn}
                        fitContent
                    />
                </Grid>
            </Grid>
        </PaperBlock>
    );
};
