import {PovoleneTypyPriloh} from '@eon.cz/apollo13-graphql-web';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash/uniqBy';
import {FC, ReactNode, useEffect, useState} from 'react';
import {DropEvent, FileRejection, FileWithPath} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import {NotificationType} from '../../../models/notification/NotificationModel';
import {BackendEndpoints} from '../../BackendEndpoints';
import {FileAllowedTypeImport} from '../../constants';
import {ValidationError} from '../../utils/CommonTypes';
import {DetailValueTitle} from '../detail/DetailValueTitle';
import {ConfirmDialog} from '../dialogs/ConfirmDialog';
import {useAddNotification} from '../notifications/actions/NotificationsActions';
import {FileInput} from './FileInput';
import {FileService} from './FileService';
import {PrilohyFormError} from './PrilohyFormError';
import {PrilohyList} from './PrilohyList';
import {
    PrilohaAkce,
    PrilohaBase,
    PrilohaKNahrani,
    PrilohyUtils,
    SmazatAkceBase,
    StahnoutAkce,
    hasOriginalFilenameSpecialCharacters,
    splitterPriloha,
} from './utils/PrilohyUtils';
import {validatePrilohy} from './utils/ValidacePrilohy';

type Props = {
    /**
     * Seznam archivovaných příloh (= příloh už v databázi)
     */
    readonly archivovane?: PrilohaBase[];

    /**
     * Seznam příloh k nahrání - na začátku prázdný seznam, pak hodnota, která byla nastavena přes onChangeKNahrani
     */
    readonly kNahrani: PrilohaKNahrani[];

    /**
     * Maximální počet příloh, které je možné nahrát
     */
    readonly limit?: number;

    /**
     * Kontroluje, zda byl vybrán typ přílohy u všech souborů
     */
    readonly isAllSetTypyPriloh: boolean;

    /**
     * Voláno pokud se mají změnit přílohy k nahrání
     */
    readonly onChangeKNahrani: (kNahrani: PrilohaKNahrani[]) => void;

    /**
     * U nahrávané přílohy změní její typ podle ID v local state
     */
    readonly onChangeTypPrilohy?: (id: number, typ: unknown) => void;

    /**
     * Smaže soubor nahraný na serveru podle id. Nemusí být uvedeno pokud není uvedeno ani archivovane
     */
    readonly onDelete?: (id: string) => void;

    /**
     * Vykreslí children, dostává jako parametr funkci která se má zavolat v případě dropnutí souboru. (Pro použití v FileInputField.)
     */
    readonly children?: (handleDrop: (accepted: File[], rejected: FileRejection[], event: DropEvent) => void, limit?: number) => ReactNode;

    /**
     * Nastaví hodnotu podle toho, jestli validace příloh vrátí chybu nebo ne
     */
    readonly setIsValidationError: (errors: boolean) => void;

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypyPriloh?: PovoleneTypyPriloh;
    /**
     * Povolené typy příloh
     */
    readonly typPrilohy: unknown;
    readonly validateAllPrilohySize: boolean;
    readonly parseId: string | undefined;
};

const getArchivovaneAkce = (parseId?: string, archivovane?: PrilohaBase[], onDeleteAction?: (id: string) => void): PrilohaAkce[] => {
    if (!archivovane || archivovane.length === 0) {
        return [];
    }

    return [
        {
            ...StahnoutAkce,
            onClick: (item: PrilohaBase) => window.open(`/api/${BackendEndpoints.DOWNLOAD}?fileId=${item.id}&zadostId=${parseId}`),
        },
        {
            ...SmazatAkceBase,
            onClick: (item: PrilohaBase) => onDeleteAction?.(item.id as string),
        },
    ];
};

/**
 * Zobrazí správnou hlášku o zbývajícím počtu souborů
 */
const UploadMoznyPocet: FC<{pocet: number}> = ({pocet}) => {
    if (pocet === 0) {
        return <FormattedMessage id="prilohy.muzete.vlozit.0" />;
    }
    if (pocet === 1) {
        return <FormattedMessage id="prilohy.muzete.vlozit.1" />;
    }
    if (pocet < 5) {
        return <FormattedMessage id="prilohy.muzete.vlozit.2" values={{pocet}} />;
    }
    return <FormattedMessage id="prilohy.muzete.vlozit.5" values={{pocet}} />;
};

/**
 * Komponenta zobrazuje vstupní pro nahrávání nových příloh, seznam nahrávaných příloh, případně seznam již nahraných (archivovaných) příloh.
 */
export const PrilohyUploadPanel: FC<Props> = ({
    archivovane,
    kNahrani,
    limit,
    onChangeKNahrani,
    onChangeTypPrilohy,
    isAllSetTypyPriloh,
    onDelete,
    children,
    setIsValidationError,
    povoleneTypyPriloh,
    typPrilohy,
    validateAllPrilohySize,
    parseId,
}) => {
    const {addNotification} = useAddNotification();
    // Používáme pro generování ID pro nahrávané soubory - ta ID se pak používají při renderu jako key property
    const [dalsiId, setDalsiId] = useState<number>(1);
    const [kNahraniToDelete, setKNahraniToDelete] = useState<number | null>(null);
    const [archivovanaToDelete, setArchivovanaToDelete] = useState<string | null>(null);
    const [showKNahraniDeleteConfirm, setShowKNahraniDeleteConfirm] = useState(false);
    const [showArchivovanaDeleteConfirm, setShowArchivovanaDeleteConfirm] = useState(false);

    const prilohyKValidaci = PrilohyUtils.prilohyToUploadValidationInput(kNahrani);
    // Validuje nahrávané přílohy a mapuje k nim validační chyby podle indexu souboru.
    const errors: ValidationError[] = validatePrilohy(
        prilohyKValidaci,
        isAllSetTypyPriloh,
        validateAllPrilohySize,
        povoleneTypyPriloh?.typyPriloh ?? [],
        archivovane,
    );
    // Ze všech chyb vyrobí unikátní seznam globálních chyb podle kódu
    const uploadErrors: ValidationError[] = uniqBy(errors, 'code');
    useEffect(() => {
        setIsValidationError(errors.length > 0);
    });
    // Seznam příloh obohacený o namapované chyby
    const currentKNahrani: PrilohaKNahrani[] = kNahrani.map((priloha, prilohaIndex) => ({
        ...priloha,
        errors: errors.filter((e) => e.index === prilohaIndex),
    }));

    // Spočteme, kolik souborů ještě můžeme nahrát
    const zbylyLimit = limit ? limit - (archivovane ? archivovane.length : 0) - kNahrani.length : undefined;

    // Kontroluje, zda má dojít ke zobrazení nápovědy, aby uživatel doplnil typ přílohy
    const showSpecifikovatTypHint = !isAllSetTypyPriloh;

    const typyPriloh = povoleneTypyPriloh?.typyPriloh ?? [typPrilohy];
    /**
     * Handler pro dropnutí souborů.
     * @param {File[]} accepted aktuálně vybrané soubory k nahrání
     * Pokud je komponenta součástí formuláře, tato funkce dispatchne změnu stavu příloh do reduxu.
     * Nicméně je nutno udržovat dva zdroje pravdy - local state a redux.
     * Local state kvůli správnému uložení a poslání souborů.
     * Redux state kvůli správnému provedení validací. Ten ovšem neumí držet v sobě File objekty.
     * Viz https://github.com/reduxjs/redux/issues/2276
     */
    const handleDrop = (accepted: File[]) => {
        const isAllowed = FileService.getAllowedExtensions(accepted);

        if (accepted.length === 0) {
            return;
        }

        if (limit && zbylyLimit && accepted.length > zbylyLimit) {
            addNotification({text: <FormattedMessage id="prilohy.prilis.mnoho.souboru" />, type: NotificationType.ERROR});
            return;
        }

        if (!isAllowed) {
            addNotification({
                type: NotificationType.WARNING,
                text: <FormattedMessage id={'prilohy.nepovoleny.format.extension'} values={{povoleneTypy: FileAllowedTypeImport.join(', ')}} />,
            });
            return;
        }

        // Obohatime metadaty
        let id = dalsiId;

        const noveKNahrani = accepted.map((file: FileWithPath) => {
            return {
                id: id++,
                file: new File([file], splitterPriloha({value: file.name, omitExt: false}), {type: file.type}),
                mime: (PrilohyUtils.getFileExtension(file) as string).toUpperCase(),
                nazev: file.name,
                velikost: file.size,
                typ: typyPriloh?.length === 1 ? typyPriloh[0] : undefined,
            } as PrilohaKNahrani;
        });

        // Nastavíme novou hodnotu id
        if (id !== dalsiId) {
            setDalsiId(id);
        }

        if (noveKNahrani.length > 0) {
            // Zavoláme změnu seznamu souborů v local state
            const vseKNahrani = kNahrani.concat(noveKNahrani);
            onChangeKNahrani(vseKNahrani);
        }
    };

    // Skryje všechny potvrzovací dialogy
    const hideDialogs = () => {
        setArchivovanaToDelete(null);
        setShowArchivovanaDeleteConfirm(false);
        setKNahraniToDelete(null);
        setShowKNahraniDeleteConfirm(false);
    };

    // Otevře potvrzovací dialog smazání archivované přílohy
    const onDeleteArchivovana = (id: string) => {
        setArchivovanaToDelete(id);
        setShowArchivovanaDeleteConfirm(true);
    };

    // Handler pro smazání archivované přílohy podle ID
    const handleConfirmDeleteArchivovana = () => {
        // Odstraníme podle ID
        if (onDelete) {
            onDelete(archivovanaToDelete as string);
        }

        // Skryjeme dialogy
        hideDialogs();
    };

    // Otevře potvrzovací dialog smazání nahrané přílohy
    const onDeleteKNahrani = (item: PrilohaBase) => {
        setKNahraniToDelete(item.id as number);
        setShowKNahraniDeleteConfirm(true);
    };

    /**
     * Handler pro smazání souboru k nahrání podle ID
     * Pokud je komponenta součástí formuláře, tato funkce dispatchne změnu stavu příloh do reduxu.
     */
    const handleConfirmDeleteKNahrani = () => {
        // Odstraníme podle ID
        const filtered = kNahrani.filter((item) => (item.id as number) !== (kNahraniToDelete as number));
        // Nastavíme nový seznam
        onChangeKNahrani(filtered);

        // Skryjeme dialogy
        hideDialogs();
    };

    const archivovaneAkce = getArchivovaneAkce(parseId, archivovane, onDeleteArchivovana);

    const kNahraniAkce: PrilohaAkce[] = [
        {
            ...SmazatAkceBase,
            onClick: onDeleteKNahrani,
        },
    ];

    const hasUploadErorrs = uploadErrors.length > 0;
    const hasArchivovanePrilohy = !!archivovane && archivovane.length > 0;
    const hasKNahraniPrilohy = currentKNahrani.length > 0;
    const hasPrilohy = hasArchivovanePrilohy || hasKNahraniPrilohy;

    return (
        <Grid container spacing={2} sx={{backgroundColor: 'background.default'}}>
            <>
                <Grid size={{xs: 12}}>
                    {typyPriloh && (
                        <Typography color="primary" variant="subtitle1">
                            <FormattedMessage id={`priloha.typ.${typPrilohy}`} />
                        </Typography>
                    )}
                </Grid>
                <Grid size={{xs: 12}}>
                    {children && !hasKNahraniPrilohy ? (
                        children(handleDrop, zbylyLimit)
                    ) : (
                        <FileInput onDrop={handleDrop} limit={zbylyLimit} title="form.fileInput.aria" />
                    )}
                </Grid>

                {hasUploadErorrs &&
                    uploadErrors.map((e) => (
                        <Grid size={{xs: 12}} key={e.index}>
                            {/* Místo validace na typ přílohy (GENVAL038) zobrazí přívětivější hlášku. Ostatní chyby zobrazí tak jak přijdou. */}
                            <PrilohyFormError name={`file-helper-text-upload-error`} error={e.message} />
                        </Grid>
                    ))}
                <Grid size={{xs: 12}}>
                    {/* Pokud nejsou žádné přílohy (archivované nebo k nahrání), zobrazí příslušnou informaci */}

                    {!hasPrilohy && (
                        <Typography data-testid={`prilohy-has-not-priloha`} variant="body1">
                            <FormattedMessage id="prilohy.zadne.info" />
                        </Typography>
                    )}
                    {!!limit && (
                        <Typography data-testid={`prilohy-limit`} variant="body1">
                            <UploadMoznyPocet pocet={zbylyLimit as number} />
                        </Typography>
                    )}
                </Grid>
            </>
            {hasKNahraniPrilohy && (
                <Grid size={{xs: 12}}>
                    <DetailValueTitle title="prilohy.k.nahrani" typPrilohy={<FormattedMessage id={`priloha.typ.${typPrilohy}`} />} />
                    {showSpecifikovatTypHint && (
                        <>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'secondary.main',
                                    'font-size': '80%',
                                    fontStyle: 'italic',
                                }}
                            >
                                <FormattedMessage id="prilohy.vyberte.typ.hint" />
                            </Typography>
                            {kNahrani.filter(({nazev}) => hasOriginalFilenameSpecialCharacters(nazev)) && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'secondary.main',
                                        'font-size': '80%',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    <FormattedMessage id="prilohy.vyberte.diakritika.hint" />
                                </Typography>
                            )}
                        </>
                    )}
                    <PrilohyList list={currentKNahrani} povoleneTypy={typyPriloh} actions={kNahraniAkce} onChangeTypPrilohy={onChangeTypPrilohy} />
                </Grid>
            )}
            {hasArchivovanePrilohy && (
                <Grid size={{xs: 12}}>
                    <DetailValueTitle title="prilohy.archivovane" />
                    <PrilohyList list={archivovane as PrilohaBase[]} actions={archivovaneAkce} />
                </Grid>
            )}

            {showKNahraniDeleteConfirm && (
                <ConfirmDialog open onClickYes={handleConfirmDeleteKNahrani} onClickNo={hideDialogs} description="prilohy.potvrzeni.smazani" />
            )}
            {showArchivovanaDeleteConfirm && (
                <ConfirmDialog open onClickYes={handleConfirmDeleteArchivovana} onClickNo={hideDialogs} description="prilohy.potvrzeni.smazani" />
            )}
        </Grid>
    );
};
