import {useDispatch} from 'react-redux';
import {RootActionType} from '../../lib/rootAction';

import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {setActiveStep, setEndForm, setFormData, setFormKindSlice, setKomoditaSlice, setSuccessStep} from '../reducers/CommonSlice';
import {SouhlasFormData} from '../types';
import {FormKind} from '../utils/CommonTypes';

type CommonActionType = {
    setEndOfForm: () => void;
    resetFormData: (komodita: Komodita | undefined | null) => void;
    setFormData: (data: Partial<SouhlasFormData>) => void;
    setActiveStep: (step: number) => void;
    setSuccessStep: (step: number) => void;
    setKomodita: (komodita: Komodita | undefined | null) => void;
    setFormKind: (formKind: FormKind | undefined) => void;
};

/**
 * It returns an object with functions that dispatch actions to the Redux store
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();

    return {
        resetFormData: (komodita) => {
            dispatch({type: RootActionType.LOGOUT, payload: undefined});
            dispatch(setKomoditaSlice(komodita));
        },
        setEndOfForm: () => {
            dispatch(setEndForm());
        },

        setFormData: (data) => {
            dispatch(setFormData(data));
        },
        setActiveStep: (step) => {
            dispatch(setActiveStep(step));
        },
        setSuccessStep(step) {
            dispatch(setSuccessStep(step));
        },
        setKomodita: (komodita) => {
            dispatch(setKomoditaSlice(komodita));
        },
        setFormKind: (formKind) => {
            dispatch(setFormKindSlice(formKind));
        },
    };
};
