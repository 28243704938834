import {PovoleneTypyPriloh} from '@eon.cz/apollo13-graphql-web';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import {FC, ReactNode, useEffect} from 'react';
import {PrilohyFormError} from '../files/PrilohyFormError';
import {PrilohyUploadPanel} from '../files/PrilohyUploadPanel';
import {PrilohaBase, PrilohaKNahrani} from '../files/utils/PrilohyUtils';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../helperComponents/ErrorFocus';
import {FileInput} from './FileInput';

type Props = {
    /**
     * Text nápovědy pod polem
     */
    readonly helperText?: string;

    /**
     * Seznam archivovaných příloh (= příloh už v databázi)
     */
    readonly archivovane?: PrilohaBase[];

    /**
     * Seznam příloh k nahrání - na začátku prázdný seznam, pak hodnota, která byla nastavena přes onChangeKNahrani
     */
    readonly kNahrani: PrilohaKNahrani[];

    /**
     * Maximální počet příloh, které je možné nahrát
     */
    readonly limit?: number;

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypyPriloh?: PovoleneTypyPriloh;

    /**
     * Kontroluje, zda byl vybrán typ přílohy u všech souborů
     */
    readonly isAllSetTypyPriloh: boolean;

    /**
     * Custom seznam chyb
     */
    readonly customErrors?: Array<{message: string | ReactNode}>;

    /**
     * Voláno pokud se mají změnit přílohy k nahrání
     */
    readonly onChangeKNahrani: (kNahrani: PrilohaKNahrani[]) => void;

    /**
     * U nahrávané přílohy změní její typ podle ID v local state
     */
    readonly onChangeTypPrilohy?: (id: number, typ: unknown) => void;

    /**
     * Smaže soubor nahraný na serveru podle id. Nemusí být uvedeno pokud není uvedeno ani archivovane
     */
    readonly onDelete?: (id: string) => void;

    /**
     * Nastaví hodnotu podle toho, jestli validace příloh vrátí chybu nebo ne
     */
    readonly setIsValidationError: (error: boolean) => void;
    readonly onChange: (...event: unknown[]) => void;
    readonly error: boolean;
    readonly required: boolean;
    readonly name: string;
    readonly parseId: string | undefined;
    readonly typPrilohy: string | undefined;
    readonly validateAllPrilohySize?: boolean;
};

export const FileInputField: FC<Props> = ({
    required,
    helperText,
    archivovane,
    kNahrani,
    limit,
    customErrors,
    isAllSetTypyPriloh,
    onChangeKNahrani,
    onChangeTypPrilohy,
    onDelete,
    setIsValidationError,
    onChange,
    error,
    name,
    povoleneTypyPriloh,
    typPrilohy,
    validateAllPrilohySize = false,
    parseId,
}) => {
    useEffect(() => {
        // Pokaždé se změnou příloh se změní seznam příloh i v reduxu
        onChange(kNahrani);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kNahrani]);

    return (
        <PrilohyUploadPanel
            archivovane={archivovane}
            kNahrani={kNahrani}
            isAllSetTypyPriloh={isAllSetTypyPriloh}
            povoleneTypyPriloh={povoleneTypyPriloh}
            limit={limit}
            onChangeKNahrani={onChangeKNahrani}
            onChangeTypPrilohy={onChangeTypPrilohy}
            onDelete={onDelete}
            setIsValidationError={setIsValidationError}
            typPrilohy={typPrilohy}
            validateAllPrilohySize={validateAllPrilohySize}
            parseId={parseId}
        >
            {(handleOnDrop, zbylyLimit) => (
                <FormControl required={required} error={error} fullWidth className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                    <FileInput onDrop={handleOnDrop} limit={zbylyLimit} bottomMargin title="form.fileInput.aria" />
                    {helperText && error && (
                        <Grid container spacing={1}>
                            <Grid size={{xs: 12}}>
                                <PrilohyFormError name={`${name}-helper-text-error`} error={helperText} />
                            </Grid>
                        </Grid>
                    )}
                    {customErrors && customErrors.length > 0 && (
                        <Grid container spacing={1}>
                            {customErrors.map((error, index) => (
                                <Grid size={{xs: 12}} key={index}>
                                    <PrilohyFormError name={`${name}-helper-text-custom-error`} error={error.message} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </FormControl>
            )}
        </PrilohyUploadPanel>
    );
};
