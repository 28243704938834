import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {NextPageContext} from 'next';
import {useEffect} from 'react';
import {useCommonAction} from '../client/common/action/CommonAction';
import {Rozcestnik} from '../client/modules/Rozcestnik';

type Props = {
    komodita: Komodita | null | undefined;
};
const Index = ({komodita}: Props) => {
    const {setKomodita} = useCommonAction();
    useEffect(() => {
        if (komodita) {
            setKomodita(komodita);
        }
    }, [komodita, setKomodita]);
    return <Rozcestnik />;
};

export const getServerSideProps = async (ctx: NextPageContext) => {
    const {
        query: {komodita},
    } = ctx;

    return {
        props: {
            komodita:
                (komodita as string)?.toUpperCase() === Komodita.ELEKTRINA
                    ? Komodita.ELEKTRINA
                    : (komodita as string)?.toUpperCase() === Komodita.PLYN
                      ? Komodita.PLYN
                      : null,
        },
    };
};

export default Index;
