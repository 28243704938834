import {useQuery} from '@apollo/client';
import {Query, VyjadrovaciLinkaOdstavka, VyjadrovaciLinkaOdstavkaFormularTyp} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {AppBar, Box, IconButton, Step, StepLabel, Stepper, Toolbar, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FC, ReactNode, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Store} from '../../../lib/StoreType';
import {theme} from '../../../lib/theme';
import {NotificationType} from '../../../models/notification/NotificationModel';
import {useCommonAction} from '../../action/CommonAction';
import {PageRoute, formMapPath} from '../../constants';
import {CommonQueries} from '../../graphql/CommonQueries';
import {setActiveStep} from '../../reducers/CommonSlice';
import {Div} from '../../styledComponents/Div';
import {FormKind} from '../../utils/CommonTypes';
import {sanitizeHTML, transformPath, useMatches, useTablet} from '../../utils/CommonUtils';
import {LoadingDialog} from '../dialogs/LoadingDialog';
import {useAddNotification} from '../notifications/actions/NotificationsActions';
import {NotificationsComponent} from '../notifications/components/NotificationsComponent';
import {EgdLogo} from './EgdLogo';
import {FormOff} from './FormOff';
import {GasLogo} from './GasLogo';

type Props = {
    readonly statusCode?: number;
    readonly children: ReactNode;
};

export const PageLayout: FC<Props> = ({children}) => {
    // local state
    const [showChildren, setShowChildren] = useState(true);

    // local variables
    const tablet = useTablet();
    const matches = useMatches();

    // redux state
    const {notifications} = useSelector((state: Store) => state?.notifications);
    const {activeStep, formSuccessSteps, endOfForm, komodita, formKind} = useSelector((state: Store) => state?.common);
    // methods
    const {push, pathname, events} = useRouter();
    const {closeNotification, addNotification} = useAddNotification();
    const {resetFormData, setFormKind} = useCommonAction();

    // query
    const {loading, refetch} = useQuery<Query>(CommonQueries.gql.odstaveniFormulare, {
        fetchPolicy: 'no-cache',
        variables: {komodita},
        notifyOnNetworkStatusChange: true,
        onCompleted: (res) => {
            const sopWebFormOutages = res?.vyjadrovaciLinka?.odstavka.filter(
                ({typFormulare}) => typFormulare === VyjadrovaciLinkaOdstavkaFormularTyp.SCHVALOVACKA,
            );
            const formOff = sopWebFormOutages?.reduce((sum, acc) => {
                if (!sum[acc.typFormulare]) {
                    sum[acc.typFormulare] = {} as {[key in Komodita]: VyjadrovaciLinkaOdstavka};
                }
                sum[acc.typFormulare][acc.komodita] = acc;
                return sum;
            }, {} as FormKind);
            setFormKind(formOff);
        },
    });

    // local variables
    const odstavitElektrina = formKind?.SCHVALOVACKA?.ELEKTRINA?.komodita === komodita && !formKind?.SCHVALOVACKA?.ELEKTRINA?.aktivni;
    const odstavitPlyn = formKind?.SCHVALOVACKA?.PLYN?.komodita === komodita && !formKind?.SCHVALOVACKA?.PLYN?.aktivni;
    const odstavka = odstavitElektrina || odstavitPlyn;
    const path = transformPath(pathname, komodita, !odstavka);

    // functions
    const handleRouteEGD = () => push(komodita === Komodita.PLYN ? 'https://www.gasd.cz' : 'https://www.egd.cz');
    const handleRefresh = useCallback(() => refetch(), [refetch]);

    useLayoutEffect(() => {
        if (!formSuccessSteps?.[activeStep - 1] && pathname !== PageRoute.VSTUP && endOfForm) {
            addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.page.pathname" />});
            setShowChildren(false);
            setActiveStep(0);
            const step = formMapPath(formSuccessSteps?.[activeStep] ? activeStep : 0);
            push(step ?? PageRoute.VSTUP).then(() => setShowChildren(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, formSuccessSteps]);

    useEffect(() => {
        if (pathname === PageRoute.VSTUP) {
            setActiveStep(0);
            resetFormData(komodita);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (endOfForm && pathname !== PageRoute.PODEKOVANI) {
            setActiveStep(0);
            resetFormData(komodita);
            push(PageRoute.VSTUP);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endOfForm, pathname]);

    useEffect(() => {
        events.on('routeChangeStart', handleRefresh);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            events.off('routeChangeStart', handleRefresh);
        };
    }, [events, handleRefresh]);

    return (
        <Div
            id="body"
            sx={{
                backgroundColor: 'background.default',
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            {/* Notifications */}
            <NotificationsComponent notifications={notifications} onClose={closeNotification} />

            {/* Header */}
            <Div
                sx={{
                    height: 80,
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    margin: '0 auto',
                    maxWidth: 1680,
                }}
            >
                <IconButton
                    sx={{
                        marginLeft: 5.5,
                    }}
                    data-testid={`egd-logo-button`}
                    onClick={handleRouteEGD}
                >
                    {komodita === Komodita.ELEKTRINA && <EgdLogo width={150} height={50} pathFill={theme.palette.error.main} />}
                    {komodita === Komodita.PLYN && <GasLogo width={150} height={50} pathFill={'inherit'} />}
                </IconButton>
            </Div>

            {/* Appbar */}
            <AppBar
                position="relative"
                color="secondary"
                sx={{
                    ...(komodita === Komodita.ELEKTRINA && {
                        backgroundImage: "url('/static/images/AppBarBackground.svg')",
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        display: 'block',
                        minHeight: 204,
                    }),
                    ...(komodita !== Komodita.ELEKTRINA && {
                        display: !komodita ? 'flex' : 'block',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.secondary.main,
                        minHeight: 204,
                    }),
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0 auto',
                        maxWidth: 1680,
                    }}
                >
                    <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                            marginTop: komodita ? 2 : 0,
                        }}
                        component="div"
                    >
                        {/* rome-ignore lint/security/noDangerouslySetInnerHtml: We sanitize HTML before set to DOM */}
                        <div dangerouslySetInnerHTML={{__html: sanitizeHTML(path)}} />
                    </Typography>
                    <Typography color="inherit" variant={matches ? 'h4' : !komodita && !tablet ? 'h3' : 'h3'} sx={{alignSelf: !komodita ? 'center' : 'auto'}}>
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage
                                id={komodita === Komodita.ELEKTRINA ? 'portal.elektrina' : komodita === Komodita.PLYN ? 'portal.gas' : 'portal'}
                            />
                        </Box>
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* Zobrazení stepperu */}
            {!odstavka && komodita && (
                <Stepper
                    sx={{
                        backgroundColor: 'background.default',
                        maxWidth: 1450,
                        margin: 'auto',
                        marginTop: 3,
                        overflowX: matches ? 'auto' : 'hidden',
                    }}
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {Object.keys(PageRoute)?.map((label, index) => (
                        <Step
                            key={`${label}-${index}`}
                            sx={{
                                '& MuiStep-alternativeLabel': {
                                    width: '80%',
                                },
                            }}
                            data-testid={`step-${label}`}
                        >
                            <StepLabel>
                                <FormattedMessage id={`layout.${label.toUpperCase()}`} />
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}

            {/* Main */}
            <Div
                role="main"
                id="main"
                sx={{
                    display: 'block',
                    padding: '82px 16px',
                    maxWidth: 1450,
                    margin: 'auto',
                    height: '100%',
                }}
            >
                {loading && <LoadingDialog open />}
                {showChildren && !odstavka && children}
                {!loading && odstavka && <FormOff message={formKind?.SCHVALOVACKA[komodita ?? Komodita.ELEKTRINA]?.formZakazanHtml} refetch={handleRefresh} />}
            </Div>

            {/* Footer */}

            <Div
                sx={{
                    width: '100%',
                    height: 86,
                    left: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: 'secondary.main',
                    background: komodita === Komodita.ELEKTRINA ? 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,#261B62 100%)' : undefined,
                }}
            >
                <Div
                    sx={(theme) => ({
                        paddingTop: theme.spacing(4),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: 1,
                        maxWidth: 1680,
                        margin: 'auto',
                    })}
                >
                    {komodita === Komodita.ELEKTRINA && <EgdLogo width={100} height={33} pathFill={theme.palette.background.default} />}
                    {komodita === Komodita.PLYN && <GasLogo width={100} height={33} pathFill={theme.palette.background.default} />}
                    {komodita && (
                        <Typography
                            component={'span'}
                            variant="body2"
                            sx={{
                                color: 'background.default',
                            }}
                        >
                            <FormattedMessage id={komodita === Komodita.ELEKTRINA ? 'footer' : 'footer.gas'} values={{year: new Date().getFullYear()}} />
                        </Typography>
                    )}
                </Div>
            </Div>
        </Div>
    );
};
